<template>
  <div v-if="billing_account">
    <div v-if="error_message" v-html="error_message" class="alert alert-danger"></div>
    <div v-if="success_message" v-html="success_message" class="alert alert-success"></div>
    <q-linear-progress color="blue-grey-6" indeterminate :class="{ invisible: !loading }" />
    <b-row v-if="billing_account_">
      <b-col xl="6" lg="6" md="12" sm="12">
        <EditableCard title="Cleared Vouchers" :params.sync="billing_account_" :relationship="$d.getRelationship('BillingAccount', 'HasClearedVouchers')" />
      </b-col>
      <b-col xl="6" lg="6" md="12" sm="12">
        <EditableCard title="Cleared Deposits" :params.sync="billing_account_" :relationship="$d.getRelationship('BillingAccount', 'HasClearedDeposits')" />
      </b-col>
      <b-col xl="6" lg="6" md="12" sm="12">
        <EditableCard
          title="Current Dispensers"
          :params.sync="billing_account_"
          :relationship="$d.getRelationship('DispenserSerialNumber', 'CurrentlyToBillingAccount')"
          direction="from"
        />
      </b-col>
      <b-col xl="6" lg="6" md="12" sm="12">
        <EditableCard
          title="Current &amp; Previous Dispensers"
          :params.sync="billing_account_"
          :relationship="$d.getRelationship('DispenserSerialNumber', 'HasBeenToBillingAccount')"
          direction="from"
        />
      </b-col>
      <b-col xl="6" lg="6" md="12" sm="12">
        <DescriptorCard
          :fixed_params="$d.relationFixedParams('DispenserSerialNumber', 'CurrentlyToBillingAccount', [billing_account])"
          :_index_params="$d.relationIndexParams('DispenserSerialNumber', 'CurrentlyToBillingAccount', billing_account.id)"
          :stateIdentifier="'_billing_account_' + billing_account.id"
          class_name="DispenserSerialNumber"
          title="DispenserSerialNumber - Current Dispensers"
          :enable_add="false"
        ></DescriptorCard>
      </b-col>
      <b-col xl="6" lg="6" md="12" sm="12">
        <DescriptorCard
          :fixed_params="$d.relationFixedParams('DispenserSerialNumber', 'HasBeenToBillingAccount', [billing_account])"
          :_index_params="$d.relationIndexParams('DispenserSerialNumber', 'HasBeenToBillingAccount', billing_account.id)"
          :stateIdentifier="'_billing_account_' + billing_account.id"
          class_name="DispenserSerialNumber"
          title="DispenserSerialNumber - Current &amp; Previous Dispensers"
          :enable_add="false"
        ></DescriptorCard>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <div class="alert alert-warning">Billing Account Required for Statistics</div>
  </div>
</template>

<script>
import { QLinearProgress } from 'quasar';

export default {
  components: {
    QLinearProgress,
    EditableCard: () => import('@/descriptor/coreui/EditableCard'),
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
  },
  props: {
    billing_account: {
      required: true,
    },
  },
  data: () => {
    return {
      error_message: null,
      success_message: null,
      loading: null,

      billing_account_: null,
    };
  },
  computed: {
    id() {
      return _.get(this.billing_account, ['id'], null);
    },
    read_params() {
      return {
        with_relationships: [
          {
            from_class_name: 'BillingAccount',
            relationship_name: 'HasClearedVouchers',
            with_virtual_properties: true,
          },
          {
            from_class_name: 'BillingAccount',
            relationship_name: 'HasClearedDeposits',
            with_virtual_properties: true,
          },
          {
            from_class_name: 'DispenserSerialNumber',
            relationship_name: 'CurrentlyToBillingAccount',
            with_virtual_properties: true,
          },
          {
            from_class_name: 'DispenserSerialNumber',
            relationship_name: 'HasBeenToBillingAccount',
            with_virtual_properties: true,
          },
        ],
      };
    },
  },
  watch: {
    billing_account: {
      immediate: true,
      handler(to, from) {
        this.get();
      },
    },
  },
  // created() {},
  methods: {
    get() {
      if (this.id) {
        this.loading = true;
        this.$d.request(
          'read',
          'BillingAccount',
          Object.assign(this.read_params, {
            id: this.id,
          }),
          billing_account => {
            // this.$d.setCache(stateName, account);
            this.billing_account_ = billing_account;

            this.error_message = null;
          },
          error_message => {
            this.error_message = error_message;
          },
          () => {
            this.loading = false;
          }
        );
      } else {
        console.error('no id, cannot read');
      }
    },
  },
};
</script>
